import { useEffect } from 'react'
import { Form } from 'antd'
import { Tenant } from '../../../models/tenant'
import { CreatePricingModalWrapper } from '../../../atom/price'
import { ChargingSectionModal } from './charging-section'
import { LoiteringSectionModal } from './loitering-section'
import { ConstantsSectionModal } from './constants-section'
import { FooterSectionModal } from './footer-section'
import { usePricingSchemas } from '../../../hooks/usePricingSchemas'
import { NameSectionModal } from './name-section'
import { NewListing } from 'models/listing'
import { PricingSchemaPayload } from 'models/price'

interface props {
  tenant: Tenant
  current: number
  listings: NewListing[]
  onCancel: () => void
  onOk: () => void
  next: () => void
  setListings: (listings: NewListing[]) => void
  onFinish: (values: PricingSchemaPayload, modal: string, listings: NewListing[], nextPageInModal?: boolean) => void
  setPricingSchemaValues: (pricingSchemaValues: PricingSchemaPayload) => void
}

const CreatePricingSchemaModal: React.FC<props> = ({
  tenant,
  current,
  listings,
  onCancel,
  onOk,
  next,
  setListings,
  setPricingSchemaValues,
}) => {
  const [form] = Form.useForm()
  const {
    loading,
    initialValues,
    nameExists,
    eventSchedules,
    timeOfDay,
    timeOfDayLoitering,
    enableCurtailmentAdjustment,
    loiteringType,
    allListings,
    pricingType,
    nextInModal,
    setLoiteringType,
    setEnableCurtailmentAdjustment,
    nameCheck,
    getPricingNames,
    setPricePerUnitLoitering,
    setPricePerUnit,
    setTimeOfDay,
    setTimeOfDayLoitering,
    setAllListings,
    setPricingType,
    setNextInModal,
    onFinish,
  } = usePricingSchemas(tenant, onOk, onCancel)

  useEffect(() => {
    if (nextInModal) {
      setPricingSchemaValues({
        ...form.getFieldsValue(true),
        enable_time_of_day: timeOfDay,
        enable_time_of_day_loitering: timeOfDayLoitering,
      })
      onFinish(form.getFieldsValue(true), 'createPricingSchema', listings, true)
      onOk()
      next()
    }
    setNextInModal(false)
  }, [nextInModal])

  useEffect(() => {
    getPricingNames(Number(tenant.id))
  }, [])

  useEffect(() => {
    return () => {}
  }, [])

  return (
    <CreatePricingModalWrapper>
      <Form
        colon={false}
        form={form}
        layout="horizontal"
        requiredMark={true}
        labelAlign="left"
        initialValues={initialValues}
        onFinish={(values) => {
          setPricingSchemaValues({
            ...values,
            enable_time_of_day: timeOfDay,
            enable_time_of_day_loitering: timeOfDayLoitering,
          })
          onFinish(values, 'createPricingSchema', listings)
        }}
        labelWrap={true}
      >
        <NameSectionModal
          tenant={tenant}
          nameExists={nameExists}
          allListings={allListings}
          type="create"
          nameCheck={nameCheck}
          getPricingNames={getPricingNames}
          setListings={setListings}
          setAllListings={setAllListings}
        />
        <ChargingSectionModal
          form={form}
          isCanadaSite={tenant.location?.country === 'CA'}
          timeOfDay={timeOfDay}
          eventSchedules={eventSchedules}
          enableCurtailmentAdjustment={enableCurtailmentAdjustment}
          priceType={pricingType}
          setPricePerUnit={setPricePerUnit}
          setTimeOfDay={setTimeOfDay}
          setEnableCurtailmentAdjustment={setEnableCurtailmentAdjustment}
          setPriceType={setPricingType}
        />
        <LoiteringSectionModal
          form={form}
          loiteringType={loiteringType}
          timeOfDayLoitering={timeOfDayLoitering}
          eventSchedules={eventSchedules}
          setPricePerUnitLoitering={setPricePerUnitLoitering}
          setTimeOfDayLoitering={setTimeOfDayLoitering}
          setLoiteringType={setLoiteringType}
        />
        <ConstantsSectionModal />
        <FooterSectionModal
          loading={loading}
          form={form}
          nameExists={nameExists}
          current={current}
          type="create"
          onCancel={onCancel}
          setNextInModal={setNextInModal}
        />
      </Form>
    </CreatePricingModalWrapper>
  )
}

export default CreatePricingSchemaModal
